import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavbarMenuContext from '../../../contexts/NavbarMenuContext';
import { config, storageKey, POLLING_INTERVAL } from '../../../config/constants';
import Storage from '../../../utils/Storage';
import { getUnreadMesssageCount, updateSettings, updateOriginalSiteOnLogout } from '../../../api/authentication';
import { updateProfileData, updatedAuthenticationStatus, updateUserToken, updateRefreshToken } from '../../../redux/modules/authentication';
import ProfileMenu from './ProfileMenu';

type Props = {
    className?: String
}

export default function ProfileMenuContainer({ className = '' }: Props): React$Element<any> {
    const dispatch = useDispatch();
    const history = useHistory();
    const [msgCount, setMsgCount] = useState(0);
    const { profileData } = useSelector(({ authentication }) => authentication);
    const { isBrowserIdle } = useContext(NavbarMenuContext);
    const { i18n } = useTranslation();

    useEffect(() => {
        getUnreadMsgCount();
        pollUnreadMsgCount();
    }, []);

    const updateOnlineStatus = async () => {
        const newStatus = !profileData.is_online;

        try {
            const response = await updateSettings({
                online: newStatus
            });

            if (response['online status update'] === 'success') {
                dispatch(updateProfileData({ ...profileData, is_online: newStatus }));
            }
        } catch (error) {
            console.error(`Update online status: ${error.message}`);
        }
    };

    const getUnreadMsgCount = async () => {
        try {
            const response = await getUnreadMesssageCount();

            setMsgCount(response.unread_messages);
        } catch (error) {
            console.error(`Get unread message count: ${error.message}`);
        }
    };

    const pollUnreadMsgCount = () => {
        setInterval(async () => {
            if (!isBrowserIdle.current) {
                try {
                    const response = await getUnreadMesssageCount();

                    setMsgCount(response.unread_messages);
                } catch (error) {
                    console.error(`Poll unread message count: ${error.message}`);
                }
            }
        }, POLLING_INTERVAL);
    };

    const redirectToProfile = () => {
        history.push('/profile');
    };

    const redirectToMessages = () => {
        window.location = (`${config.apiHost}${i18n.language}/account/inbox/`);
    };

    const redirectToThreads = (type) => { // threads, mentions, posts
        history.push(`/threads?section=${type}`);
    };

    const logout = () => {
        try {
            updateOriginalSiteOnLogout();

            Storage.removeItem(storageKey.userToken);
            Storage.removeItem(storageKey.refreshToken);
            dispatch(updateUserToken(null));
            dispatch(updateRefreshToken(null));
            dispatch(updatedAuthenticationStatus(false));
            dispatch(updateProfileData({}));
            window.location = `${config.apiHost}${i18n.language}/`;
        } catch (error) {
            console.error(`Logout: ${error.message}`);
        }
    };

    return (
        <ProfileMenu
            profileData={profileData}
            msgCount={msgCount}
            updateOnlineStatus={updateOnlineStatus}
            redirectToProfile={redirectToProfile}
            redirectToMessages={redirectToMessages}
            redirectToThreads={redirectToThreads}
            logout={logout} />
    );
}
