import React from 'react';

const NotFound = React.lazy(() => import('../containers/NotFound/NotFoundContainer'));
const AuthRedirection = React.lazy(() => import('../containers/AuthRedirection/AuthRedirectionScreen'));
const Logout = React.lazy(() => import('../containers/LogoutRedirection/LogoutRedirectionScreen'));
const Consultation = React.lazy(() => import('../containers/Consultation/ConsultationContainer'));
const Profile = React.lazy(() => import('../containers/Profile/ProfileWrapper'));
const Topic = React.lazy(() => import('../containers/Topic/TopicContainer'));
const Threads = React.lazy(() => import('../containers/Threads/ThreadsContainer'));
// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

const routes = [{
    // mostly this will be the landing page
    path: '/',
    exact: true,
    name: 'Home',
    component: Profile
}, {
    path: '/redirect',
    exact: true,
    // authedRoute: false,
    name: 'AuthRedirection',
    component: AuthRedirection
}, {
    path: '/logout',
    exact: true,
    authedRoute: true,
    name: 'Logout',
    component: Logout
}, {
    path: '/profile',
    exact: true,
    authedRoute: true,
    name: 'Profile',
    component: Profile
}, {
    path: '/consultation/:id',
    exact: true,
    authedRoute: true,
    name: 'Consultation',
    component: Consultation
}, {
    path: '/consultation/:consultationId/topic/:id',
    exact: true,
    authedRoute: true,
    name: 'Topic',
    component: Topic
}, {
    path: '/threads',
    exact: true,
    authedRoute: true,
    name: 'Threads',
    component: Threads
}, // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
{
    path: '*',
    name: 'NotFound',
    component: NotFound
}];

export default routes;
