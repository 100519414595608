import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { POLLING_INTERVAL } from '../../../config/constants';
import NavbarMenuContext from '../../../contexts/NavbarMenuContext';
import Notification from './Notification';
import { getNotifications, makeNotifRead, makeAllNotifsRead } from '../../../api/authentication';

const ITEMS_PER_FETCH = 10;

type Props = {
    className?: String
}

export default function NotificationContainer({ className = '' }: Props): React$Element<any> {
    const history = useHistory();
    const [notifications, setNotifications] = useState([]);
    const [hasUnreadNotifs, setHasUnreadNotifs] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(ITEMS_PER_FETCH);
    const lastFetchedId = useRef(-1);
    const interval = useRef(null);

    const [newNotifBatch, setNewNotifBatch] = useState([]);
    const [firstNotifToday, setFirstNotifToday] = useState(null);
    const { isBrowserIdle } = useContext(NavbarMenuContext);

    const viewNotif = async (notification, handleClose) => {
        try {
            const response = await makeNotifRead(notification.id);

            if (response.read !== 'success') {
                setNotifications(notifications.map((notif) => {
                    if (notif.id === notification.id) {
                        return {
                            ...notif,
                            unread: false
                        };
                    }

                    return notif;
                }));
            }
        } catch (error) {
            console.error(`Unread notification: ${error.message}`);
        }

        history.push(`/consultation/${notification.content_consultation_id}/topic/${notification.content_post_id}?notif=${notification.content_parent_comment_id}`); // comment id of parent comment
        handleClose();
    };

    useEffect(() => {
        prepareData();
        pollNotifs();

        return () => {
            clearInterval(clearInterval(interval.current));
        };
    }, []);

    const pollNotifs = () => {
        interval.current = setInterval(async () => {
            if (!isBrowserIdle.current) {
                try {
                    const response = await getNotifications(0, ITEMS_PER_FETCH, lastFetchedId.current);

                    setNotifications((prevNotifs) => [...response, ...prevNotifs]);
                } catch (error) {
                    console.error(`Polling notifications: ${error.message}`);
                }
            }
        }, POLLING_INTERVAL);
    };

    useEffect(() => {
        if (notifications.length === 0) return;

        let hasNotif = false;

        for (const notif of notifications) {
            if (notif.unread) {
                hasNotif = true;
                break;
            }
        }

        if (hasNotif) setHasUnreadNotifs(true);
        else setHasUnreadNotifs(false);
    }, [notifications]);

    const prepareData = async () => {
        try {
            const response = await getNotifications(startIndex, endIndex);

            setNotifications(response);

            if (response.length > 0) lastFetchedId.current = response[response.length - 1].id;
        } catch (error) {
            console.error(`Fetching notifications: ${error.message}`);
        }
    };

    const fetchMoreNotifications = async (setHasMore: Function) => {
        const newStartIndex = startIndex + ITEMS_PER_FETCH;
        const newEndIndex = endIndex + ITEMS_PER_FETCH;

        try {
            const response = await getNotifications(newStartIndex, newEndIndex);

            if (response.length === 0 || response.length < ITEMS_PER_FETCH) setHasMore(false);
            if (response.length > 0) lastFetchedId.current = response[response.length - 1].id;

            setNewNotifBatch(response);
            setStartIndex(newStartIndex);
            setEndIndex(newEndIndex);
        } catch (error) {
            console.error(`Fetch more notifications: ${error.message}`);
        }
    };

    useEffect(() => {
        setNotifications([...notifications, ...newNotifBatch]);
    }, [newNotifBatch]);

    const updateAllNotifsAsRead = async () => {
        try {
            await makeAllNotifsRead();
            setNotifications(notifications.map((notif) => {
                return {
                    ...notif,
                    unread: false
                };
            }));
        } catch (error) {
            console.error(`Set all notifications as read: ${error.message}`);
        }
    };

    return (
        <>
            <Notification className={className}
                notifications={notifications}
                firstNotifToday={firstNotifToday}
                fetchMoreNotifications={fetchMoreNotifications}
                setNotifications={setNotifications}
                setFirstNotifToday={setFirstNotifToday}
                viewNotif={viewNotif}
                hasUnreadNotifs={hasUnreadNotifs}
                updateAllNotifsAsRead={updateAllNotifsAsRead} />
        </>
    );
}
