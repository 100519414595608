// @flow
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const SET_AUTHENTICATION_STATUS = 'SET_AUTHENTICATION_STATUS';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const SET_PREFERRED_LANGUAGE = 'SET_PREFERRED_LANGUAGE';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_LAYOUT_DIRECTION = 'SET_LAYOUT_DIRECTION';
const SET_LOGGING_OUT = 'SET_LOGGING_OUT';

export function updateUserToken(userToken: Object): {userToken: string} {
    return {
        type: SET_ACCESS_TOKEN,
        userToken
    };
}

export function updateRefreshToken(refreshToken: Object): {refreshToken: string} {
    return {
        type: SET_REFRESH_TOKEN,
        refreshToken
    };
}

export function updatedAuthenticationStatus(isAuthed: Boolean): {data: Object} {
    return {
        type: SET_AUTHENTICATION_STATUS,
        isAuthed
    };
}

export function authUser(data: Object): {data: Object} {
    return {
        type: AUTHENTICATE_USER,
        data
    };
}

export function updateProfileData(data: Object): {data: Object} {
    return {
        type: SET_PROFILE_DATA,
        data
    };
}

export function updatePreferredLanguage(preferredLanguage: String): {data: Object} {
    return {
        type: SET_PREFERRED_LANGUAGE,
        preferredLanguage
    };
}

export function updateLanguage(language: String): {data: Object} {
    return {
        type: SET_LANGUAGE,
        language
    };
}

export function updateLayoutDirection(direction: String): any {
    return {
        type: SET_LAYOUT_DIRECTION,
        direction
    };
}

export function setLoggingOut(): {} {
    return {
        type: SET_LOGGING_OUT
    };
}

const initialState = {
    isAuthed: false,
    isLoggingOut: false,
    profileData: {},
    userToken: null,
    refreshToken: null,
    preferredLanguage: null
};

export default function authentication(state: Object = initialState, action: Object):
  | any
  | {
    isAuthed: boolean,
    isLoggingOut: boolean,
    profileData: {},
    userToken: null,
    refreshToken: null,
    preferredLanguage: null,
    language: null,
    layoutDirection: 'ltr'
  } {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                isLoggingOut: false,
                userToken: action.userToken
            };
        case SET_REFRESH_TOKEN:
            return {
                ...state,
                isLoggingOut: false,
                refreshToken: action.refreshToken
            };
        case AUTHENTICATE_USER:
            return {
                ...state,
                profileData: action.data,
                isAuthed: true
            };
        case SET_AUTHENTICATION_STATUS:
            return {
                ...state,
                isAuthed: action.isAuthed
            };
        case SET_PROFILE_DATA:
            return {
                ...state,
                profileData: {
                    ...state.profileData,
                    ...action.data
                }
            };
        case SET_PREFERRED_LANGUAGE:
            return {
                ...state,
                preferredLanguage: action.preferredLanguage
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case SET_LAYOUT_DIRECTION:
            return {
                ...state,
                direction: action.direction
            };
        case SET_LOGGING_OUT:
            return {
                ...initialState,
                isLoggingOut: true
            };
        default:
            return state;
    }
}
