import { createTheme } from '@material-ui/core';

const theme = createTheme({
    direction: 'ltr',
    palette: {
        primary: {
            main: '#FE8E11'
        },
        info: {
            main: '#6491C6'
        },
        error: {
            main: '#DD2C42'
        },
        warning: {
            main: '#F5A623'
        },
        success: {
            main: '#74CD75'
        },
        neutrals: {
            50: '#FFFFFF',
            100: '#FAFAFA',
            200: '#F5F5F5',
            300: '#E8E8E8',
            400: '#D9D9D9',
            500: '#BFBFBF',
            600: '#8C8C8C',
            700: '#595959',
            800: '#262626',
            900: '#0D0D0D'
        },
        green: {
            50: '#EEF9EE',
            100: '#D5F0D6',
            200: '#BAE6BA',
            300: '#9EDC9E',
            400: '#89D58A',
            500: '#74CD75',
            600: '#6CC86D',
            700: '#61C162',
            800: '#57BA58',
            900: '#44AE45'
        },
        orange: {
            50: '#FFF1E2',
            100: '#FFDDB8',
            200: '#FFC788',
            300: '#FEB058',
            400: '#FE9F35',
            500: '#FE8E11',
            600: '#FE860F',
            700: '#FE7B0C',
            800: '#FE710A',
            900: '#FD5F05'
        },
        orangeSupport: {
            50: '#FEF4E5',
            100: '#FCE4BD',
            200: '#FAD391',
            300: '#F8C165',
            400: '#F7B344',
            500: '#F5A623',
            600: '#F49E1F',
            700: '#F2951A',
            800: '#F08B15',
            900: '#EE7B0C'
        },
        red: {
            50: '#FDE4E5',
            100: '#FABBBD',
            200: '#F68D92',
            300: '#F25F66',
            400: '#F03D45',
            500: '#ED1B24',
            600: '#EB1820',
            700: '#E8141B',
            800: '#E51016',
            900: '#E0080D'
        },
        redSupport: {
            50: '#FBE6E8',
            100: '#F5C0C6',
            200: '#EE96A1',
            300: '#E76B7B',
            400: '#E24C5E',
            500: '#DD2C42',
            600: '#D9273C',
            700: '#D42133',
            800: '#CF1B2B',
            900: '#C7101D'
        },
        blue: {
            50: '#ECF2F8',
            100: '#D1DEEE',
            200: '#B2C8E3',
            300: '#93B2D7',
            400: '#7BA2CF',
            500: '#6491C6',
            600: '#5C89C0',
            700: '#527EB9',
            800: '#4874B1',
            900: '#3662A4'
        },
        text: {
            primary: '#262626',
            secondary: 'rgba(0,0,0,0.6)',
            disabled: 'rgba(0,0,0,0.12)'
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Inter',
            'Noto Sans Myanmar'
        ].join(','),
        fontSize: 14,
        color: 'red'
    }
});

export default theme;
