import makeApiRequest from './makeApiCall';

const BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL}`;

export async function getProfileData(): any {
    return await makeApiRequest(`${BACKEND_URL}me`, 'GET', null);
}

export async function getNewToken(data: object): any {
    return await makeApiRequest(`${BACKEND_URL}token/refresh-token`, 'POST', data, 'application/json');
}

export async function getUnreadMesssageCount(): any {
    return await makeApiRequest(`${BACKEND_URL}messages/unread`, 'GET', null);
}

export async function getNotifications(start: number, end: number, lastFetchedId: number): any {
    return await makeApiRequest(`${BACKEND_URL}me/notifications?start=${start}&end=${end}${lastFetchedId ? '&last_fetch_id=' + lastFetchedId : ''}`, 'GET', null);
}

export async function makeNotifRead(notifId: number): any {
    return await makeApiRequest(`${BACKEND_URL}me/notifications/${notifId}/read`, 'POST', null, 'application/json');
}

export async function makeAllNotifsRead(): any {
    return await makeApiRequest(`${BACKEND_URL}me/notifications/read-all`, 'POST', null);
}

export async function updateSettings(data: object): any {
    return await makeApiRequest(`${BACKEND_URL}settings`, 'POST', data, 'application/json');
}

export async function updateOriginalSiteOnLogout(): any {
    return await makeApiRequest(`${BACKEND_URL}logout`, 'POST', null);
}

export async function getConsultationSlug(consultationId: number): any {
    return await makeApiRequest(`${BACKEND_URL}public/consultation?id=${consultationId}`, 'GET', null);
}
