import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import routes from '../../config/routes';
import Loading from '../../components/base/Loading/Loading';
import AppLoadingContainer from '../AppLoading/AppLoadingContainer';
import Navbar from '../../components/base/Navbar/Navbar';
import Footer from '../../components/base/Footer/Footer';
import theme from '../../themes/base/Theme';
import 'react-toastify/dist/ReactToastify.css';

const loading = () => (
    <Loading />
);

function App() {
    const location = useLocation();
    const { isAuthed } = useSelector(({ authentication }) => authentication);

    return (
        <AppLoadingContainer>
            <StylesProvider injectFirst={true}>
                <ThemeProvider theme={theme}>
                    <div id='outer-container'>
                        <Navbar />
                        <React.Suspense fallback={loading()}>
                            <Switch>
                                {routes.map((route, i) => (
                                    route.authedRoute
                                        ? <PrivateRoute key={i} {...route} isAuthed={isAuthed} />
                                        : <RouteWithSubRoutes key={i} {...route} />
                                ))}
                            </Switch>
                        </React.Suspense>
                    </div>
                    {isAuthed && !/\/redirect/.test(location.pathname) && <Footer />}
                </ThemeProvider>
            </StylesProvider>

            <ToastContainer
                hideProgressBar={true}
                closeButton={false}
                closeOnClick={true}
                icon={false}
                theme='colored'
                position='bottom-center' />
        </AppLoadingContainer>
    );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => (
                // pass the sub-routes down to keep nesting
                <Component {...props} routes={props.routes} />
            )} />
    );
}

function PrivateRoute({ component: Component, isAuthed, ...rest }) {
    return (
        <Route {...rest} style={{ minHeight: 300, backgroundColor: 'red' }} render={(routeProps) => (
            isAuthed
                ? <Component className='content' {...routeProps} />
                : <Redirect to={{
                    pathname: '/',
                    state: { from: routeProps.location }
                }}/>
        )}/>
    );
}

export default App;
