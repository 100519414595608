import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import Storage from '../../../utils/Storage';
import { design, languageOptions, storageKey } from '../../../config/constants';
import { updateLanguage, updateLayoutDirection } from '../../../redux/modules/authentication';
import { ReactComponent as Logo } from '../../../assets/svg/platform4dialogue-logo.svg';
import {
    useTheme,
    makeStyles,
    AppBar,
    Toolbar,
    FormControl,
    Select,
    MenuItem,
    Box
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import NavbarMenu from '../NavbarMenu/NavbarMenu';
import './navbar.scss';

const LTR = 'ltr';
const RTL = 'rtl';

const useStyles = makeStyles(theme => ({
    nav: {
        height: 80,
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    toolbar: {
        height: 80,
        paddingLeft: design.indentPadding, // 160px + 160px
        paddingRight: design.indentPadding,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    logoContainer: {
        flexGrow: 1
    },
    logo: {
        cursor: 'pointer'
    }
}));

type Props = {
    className?: string
}

export default function Navbar({ className = '' }: Props): React$Element<any> {
    const classes = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { language: lng } = useSelector(({ authentication }) => authentication);
    const [language, setLanguage] = useState(lng);

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        dispatch(updateLanguage(lng));
        Storage.setItem(storageKey.language, lng);
        document.getElementsByTagName('body')[0].setAttribute('dir', LTR);
        theme.direction = LTR;

        if (lng === 'ar') {
            dispatch(updateLayoutDirection(RTL));
            document.getElementsByTagName('body')[0].setAttribute('dir', RTL);
            theme.direction = RTL;
        }
    };

    const redirectToProfile = () => {
        history.push('/');
    };

    return (
        <>
            <AppBar className={classes.nav}
                position='sticky' elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.logoContainer}>
                        <Logo onClick={redirectToProfile}
                            className={classes.logo} />
                    </Box>

                    <NavbarMenu />
                    <FormControl size='small'>
                        <Select
                            id='language-select'
                            variant='outlined'
                            value={language}
                            onChange={(e) => changeLanguage(e.target.value)}
                            inputProps={{ 'aria-label': 'Without label' }}
                            IconComponent = {KeyboardArrowDown}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                },
                                getContentAnchorEl: null
                            }}>
                            {languageOptions.map((option, i) => (
                                <MenuItem value={option.value} key={i}>{parse(option.label)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Toolbar>
            </AppBar>
        </>
    );
}
