/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as qs from 'qs';
import jwt_decode from 'jwt-decode';
import Storage from '../../utils/Storage';
import { Typography } from '@material-ui/core';
import { isTokenValid } from '../../utils/Auth';
import { config, storageKey, languageOptions } from '../../config/constants';
import { getProfileData, getNewToken, getConsultationSlug } from '../../api/authentication';
import { updateUserToken, updatePreferredLanguage, updateLanguage, authUser } from '../../redux/modules/authentication';
import Loading from '../../components/base/Loading/Loading';
import WrapperBackground from '../../components/base/WrapperBackground/WrapperBackground';
import './app-loading.scss';

type Props = {
    children: any,
    route: string,
}

export default function AppLoadingContainer({
    children,
    ...props
}: Props): any | React$Element<"div"> {
    const dispatch = useDispatch();
    const location = useLocation();
    const { i18n } = useTranslation();
    const { profileData } = useSelector(({ authentication }) => authentication);
    const refreshToken = qs.parse(location.search, { ignoreQueryPrefix: true }).refresh_token;
    const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token;

    const [isAppReady, setAppReady] = useState(false);
    const [isErrorLoaded, setErrorLoaded] = useState(false);

    useEffect(() => {
        const accessToken = Storage.getItem(storageKey.userToken);

        // if token and refreshToken exist (i.e. page is on redirect?token=123),
        // save tokens on local storage
        if (location.pathname.includes('redirect')) {
            if (token) { // & refreshToken
                Storage.setItem(storageKey.userToken, token);
                Storage.setItem(storageKey.refreshToken, refreshToken);
            } else if (!accessToken) {
                redirectToLogin();

                return;
            }
        } else if (!accessToken) { // if not on redirect and !accesstoken
            if (location.pathname.includes('consultation')) {
                redirectToConsultationAd();

                return;
            }

            redirectToLogin();
        }

        checkLanguage();
        checkAuth();
        checkAuthStatusPeriodically();
    }, []);

    const checkAuthStatusPeriodically = () => {
        setInterval(() => {
            checkAuth();
        }, 120000); // 2 mins
    };

    const redirectToLogin = () => {
        window.location = `${config.apiHost}${i18n.language}/accounts/login`;
    };

    const redirectToConsultationAd = async () => {
        const splitUrl = location.pathname.split('/');
        const response = await getConsultationSlug(splitUrl[2]);

        window.location = `${config.apiHost}${i18n.language}/a/${response.slug}/ad`;
    };

    const checkLanguage = () => {
        // check if language is set in url and update local storage
        const lang = qs.parse(location.search, { ignoreQueryPrefix: true }).lang;
        const availableLanguages = languageOptions.map((option) => option.value);

        if (lang && availableLanguages.includes(lang)) {
            Storage.setItem(storageKey.language, lang);
            dispatch(updateLanguage(lang));
            dispatch(updatePreferredLanguage(lang));
            i18n.changeLanguage(lang);
        }
    };

    const checkAuth = async () => {
        try {
            setErrorLoaded(false);

            const accessToken = Storage.getItem(storageKey.userToken);
            const refreshToken = Storage.getItem(storageKey.refreshToken);

            let tokenObj = accessToken ? jwt_decode(accessToken) : null;

            dispatch(updateUserToken(accessToken));

            // token not valid
            if (!tokenObj || (tokenObj && tokenObj.exp && !isTokenValid(tokenObj.exp)) || !tokenObj.exp) {
                const response = await getNewToken({
                    refresh_token: refreshToken
                });

                tokenObj = jwt_decode(response.token);

                dispatch(updateUserToken(response.token));
                Storage.setItem(storageKey.userToken, response.token);
            }

            if (tokenObj) { // && refreshToken
                if (Object.keys(profileData).length === 0) {
                    const response = await getProfileData();

                    if (response.detail === 'Signature verification failed') {
                        redirectToLogin();
                    } else {
                        dispatch(authUser(response));
                        dispatch(updatePreferredLanguage(response.prefered_language));
                        dispatch(updateLanguage(response.prefered_language));

                        const savedLanguage = Storage.getItem(storageKey.language);

                        if (!savedLanguage) {
                            dispatch(updateLanguage(response.prefered_language));
                            Storage.setItem(storageKey.language, response.prefered_language);
                        } else {
                            i18n.changeLanguage(savedLanguage);
                            dispatch(updateLanguage(savedLanguage));
                        }
                    }
                }
            }

            setAppReady(true);
        } catch (err) {
            setAppReady(true);
            setErrorLoaded(true);
        }
    };

    if (isAppReady) {
        return isErrorLoaded
            ? <WrapperBackground>
                <div className='app-loading__error'>
                    <Typography>Page error</Typography>
                </div>
            </WrapperBackground>
            : children;
    }

    return (
        <Loading />
    );
}
