import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Box,
    Avatar,
    Menu,
    MenuItem,
    Badge,
    Divider,
    IconButton
} from '@material-ui/core';
import { KeyboardArrowRight, ArrowDropDown } from '@material-ui/icons';
import { ReactComponent as Messages } from '../../../assets/svg/messages.svg';
import { ReactComponent as Threads } from '../../../assets/svg/threads.svg';
import { ReactComponent as Likes } from '../../../assets/svg/likes.svg';
import { ReactComponent as SavedPosts } from '../../../assets/svg/saved-posts.svg';
import { ReactComponent as Logout } from '../../../assets/svg/logout.svg';
import { useBadgeStyles } from '../../styles/classes';
import './profile-menu.scss';

const useStyles = makeStyles(theme => ({
    avatar: {
        display: 'inline-flex',
        height: 24,
        width: 24,
        marginLeft: 10,
        marginRight: 1,
        cursor: 'pointer'
    },
    divider: {
        marginRight: 10,
        marginLeft: 10
    },
    myProfileMenu: {
        padding: 15,
        paddingBottom: 30
    },
    myProfile: {
        position: 'absolute',
        top: 35,
        left: 52,
        color: '#595959'
    },
    onlineBadge: {
        marginLeft: 10
    },
    messages: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.red[500],
            color: theme.palette.common.white,
            width: 24
        },
        marginRight: 10
    },
    dropdownIcon: {
        padding: 0
    }
}));

type Props = {
    className?: String,
    profileData: Object,
    msgCount: Number,
    updateOnlineStatus: Function,
    redirectToProfile: Function,
    redirectToMessages: Function,
    redirectToThreads: Function,
    logout: Function
}

export default function ProfileMenu({
    className = '',
    profileData,
    msgCount,
    updateOnlineStatus,
    redirectToProfile,
    redirectToMessages,
    redirectToThreads,
    logout
}: Props): React$Element<any> {
    const { t, i18n } = useTranslation();
    const classes = useStyles({ dir: i18n.dir() });
    const badgeClasses = useBadgeStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Badge color='secondary' overlap='circular'
                variant={profileData.is_online ? 'dot' : 'standard'} className={`${profileData.is_online ? badgeClasses.online : ''}`}>
                <Avatar className={`${classes.avatar}`}
                    src={profileData.profile_img && `${profileData.profile_img}`}
                    onClick={handleClick}>{profileData.user.first_name.charAt(0)}</Avatar>
            </Badge>

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                PaperProps={{
                    style: {
                        width: 280
                    }
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>

                <MenuItem onClick={redirectToProfile}
                    className={classes.myProfileMenu}>
                    <Badge color='secondary' overlap='circular'
                        variant={profileData.is_online ? 'dot' : 'standard'} className={`${profileData.is_online ? badgeClasses.online : ''}`}>
                        <Avatar className={`${classes.avatar}`}>{profileData.user.first_name.charAt(0)}</Avatar>
                    </Badge>
                    <Box fontWeight='fontWeightMedium' fontSize={16}
                        ml={1} mr={1} display='flex'
                        flexGrow={1}>
                        {t('profile.hi')}, {profileData.user.first_name}!
                    </Box>
                    <KeyboardArrowRight />
                    <Box fontSize={12} className={classes.myProfile}
                        mt={1} ml={1}>
                        {t('profile.my_profile')}
                    </Box>
                </MenuItem>

                <MenuItem onClick={updateOnlineStatus}>
                    <Badge badgeContent={''} variant='dot'
                        className={`${classes.onlineBadge} ${profileData.is_online ? badgeClasses.online : badgeClasses.outline}`} />
                    <Box component='span' p={1} ml={1}
                        fontFamily='Inter, "Noto Sans Myanmar"'>{t('profile.set_status_as')} {t(`profile.${profileData.is_online ? 'away' : 'active'}`)}</Box>
                </MenuItem>

                <MenuItem onClick={redirectToMessages}>
                    <Messages />
                    <Box ml={1} p={1} display='flex'
                        flexGrow={1}>{t('profile.messages')}</Box>
                    <Badge className={classes.messages} badgeContent={msgCount} />
                </MenuItem>

                <Divider className={classes.divider} />

                <MenuItem onClick={() => redirectToThreads('threads')}>
                    <Threads />
                    <Box component='span' ml={1} p={1}
                        fontFamily='Inter, "Noto Sans Myanmar"'>{t('profile.threads')}</Box>
                </MenuItem>

                <MenuItem onClick={() => redirectToThreads('mentions')}>
                    <Likes />
                    <Box component='span' ml={1} p={1}
                        fontFamily='Inter, "Noto Sans Myanmar"'>{t('profile.mentions_and_likes')}</Box>
                </MenuItem>

                <MenuItem onClick={() => redirectToThreads('posts')}>
                    <SavedPosts />
                    <Box component='span' ml={1} p={1}
                        fontFamily='Inter, "Noto Sans Myanmar"'>{t('profile.saved_posts')}</Box>
                </MenuItem>

                <Divider className={classes.divider} />

                <MenuItem onClick={logout}>
                    <Logout />
                    <Box component='span' ml={1} p={1}
                        fontFamily='Inter, "Noto Sans Myanmar"'>{t('profile.logout')}</Box>
                </MenuItem>
            </Menu>
            <IconButton onClick={handleClick}
                className={classes.dropdownIcon}>
                <ArrowDropDown/>
            </IconButton>
        </>
    );
}
