import React, { useRef } from 'react';
import {
    makeStyles,
    Box
} from '@material-ui/core';
import IdleTimer from 'react-idle-timer';
import NavbarMenuContext from '../../../contexts/NavbarMenuContext';
import ProfileMenu from '../../modules/ProfileMenu/ProfileMenuContainer';
import Notification from '../../modules/Notification/NotificationContainer';
import './navbar-menu.scss';

const useStyles = makeStyles(theme => ({
    notificationsChip: {
        background: theme.palette.neutrals[200],
        borderRadius: 100,
        padding: 5,
        marginLeft: 16,
        marginRight: 16,
        boxSizing: 'border-box !important'
    }
}));

type Props = {
    className?: String
}

export default function MuiNavbarMenu({ className = '' }: Props): React$Element<any> {
    const classes = useStyles();
    const isBrowserIdle = useRef(false);
    const idleTimer = useRef(null);

    return (
        <NavbarMenuContext.Provider value={{ isBrowserIdle }}>
            <Box className={`navbar-menu ${className} ${classes.notificationsChip} `}
                display='flex'>
                <IdleTimer
                    ref={ref => { idleTimer.current = ref; }}
                    timeout={10000}
                    onIdle={() => { isBrowserIdle.current = true; }}
                    onActive={() => { isBrowserIdle.current = false; }}
                    debounce={250} />

                <Box component='span' className={classes.notification}>
                    <Notification />
                </Box>

                <ProfileMenu />
            </Box>
        </NavbarMenuContext.Provider>
    );
}
