// env config
export const config = {
    apiUrl: process.env.BACKEND_URL,
    apiHost: process.env.REACT_APP_BACKEND_HOST,
    pusherKey: process.env.REACT_APP_PUSHER_KEY,
    pusherCluster: process.env.REACT_APP_PUSHER_CLUSTER
};

// design measurements
export const design = {
    indentPadding: 160, // 160px + 150px
    contentPadding: 100
};

// placeholder for non existing imgs
export const placeholder = {
    img: 'https://images.unsplash.com/photo-1618710250516-ef610b20fc85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
};

// links constant
export const footerLinks = {
    consultations: 'https://www.platform4dialogue.org/en/consultations/',
    about: 'https://www.platform4dialogue.org/en/about/',
    contactUs: 'https://www.platform4dialogue.org/en/contact/',
    privacyPolicy: 'https://www.platform4dialogue.org/en/privacy/',
    termsOfService: 'https://www.platform4dialogue.org/en/terms/'
};

// keys for localstorage
export const storageKey = {
    accessToken: 'oldst_accesstoken',
    sidebarState: 'sidebarState',
    userToken: 'userToken',
    refreshToken: 'refreshToken',
    language: 'language'
};

// sort comments by
export const orderTypes = [
    {
        label: 'Most Recent',
        value: 'most_recent',
        i18n: 'most_recent'
    },
    {
        label: 'Most Popular',
        value: 'likes',
        i18n: 'most_popular'
    },
    {
        label: 'Oldest',
        value: 'oldest',
        i18n: 'oldest'
    }
];

export const languageOptions = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'العربيّة',
        value: 'ar'
    },
    {
        label: 'Espa&ntilde;ol',
        value: 'es'
    },
    {
        label: 'Fran&ccedil;ais',
        value: 'fr'
    },
    {
        label: 'မြန်မာစာ',
        value: 'my'
    }

];

// number constants
export const POLLING_INTERVAL = 30000; // 30 secs

// statuses
export const COMPLETED = 'Completed';

// threads page sections
export const THREADS = 'threads';
export const MENTIONS_AND_LIKES = 'mentions';
export const SAVED_POSTS = 'posts';
