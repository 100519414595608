import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Badge,
    Menu,
    MenuItem,
    Box,
    Avatar,
    Grid,
    Link
} from '@material-ui/core';
import { isLTR } from '../../../utils/helpers';
import { useBadgeStyles } from '../../styles/classes';
import TimeAgo from '../../base/TimeAgo/TimeAgo';
import { ReactComponent as NotificationBell } from '../../../assets/svg/notification-bell.svg';
import './notification.scss';

const useStyles = makeStyles(theme => ({
    badge: {
        marginLeft: 10,
        marginRight: 10
    },
    notification: {
        '& :hover': {
            cursor: 'pointer'
        }
    },
    menuWrapper: {
        '& .MuiList-root': {
            paddingLeft: '0px !important',
            width: '100% !important'
        }
    },
    menuItem: {
        paddingTop: 10,
        paddingBottom: 10
    },
    timeAgo: {
        color: theme.palette.neutrals[600]
    },
    notifTag: {
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.neutrals[600],
        letterSpacing: 1.2,
        marginLeft: isLTR(theme.direction) ? 15 : 0,
        marginRight: 15,
        marginBottom: 10
    },
    readText: {
        color: theme.palette.neutrals[700]
    },
    markAllAsRead: {
        cursor: 'pointer'
    }
}));

type Props = {
    className?: String,
    notifications: Array,
    firstNotifToday: Object,
    fetchMoreNotifications: Function,
    setNotifications: Function,
    setFirstNotifToday: Function,
    viewNotif: Function,
    hasUnreadNotifs: Boolean,
    updateAllNotifsAsRead: Function
}

export default function Notification({
    className = '',
    notifications,
    firstNotifToday,
    fetchMoreNotifications,
    setNotifications,
    setFirstNotifToday,
    viewNotif,
    hasUnreadNotifs,
    updateAllNotifsAsRead
}: Props): React$Element<any> {
    const classes = useStyles();
    const badgeClasses = useBadgeStyles();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const observer = useRef();
    const lastNotifRef = useCallback(node => {
        if (loading) return;
        if (!hasMore) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting) {
                setLoading(true);
                await fetchMoreNotifications(setHasMore);
                setLoading(false);
            }
        });

        if (node) observer.current.observe(node);
    }, [loading]);

    return (
        <>
            <Badge color='secondary' overlap='circular'
                variant='dot' badgeContent={hasUnreadNotifs ? '' : 0}
                className={classes.badge}>
                <NotificationBell onClick={handleClick}
                    className={classes.notification} />
            </Badge>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuWrapper}
                style={{ paddingLeft: '0px !important' }}
                MenuListProps={{
                    disablePadding: true
                }}
                PaperProps={{
                    style: {
                        height: 300,
                        width: 450
                    }
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}>

                <Box display='flex'>
                    <Box fontSize={16} fontWeight={500}
                        display='inline-block'
                        flexGrow={1}
                        p={2}>{t('text.notifications')}</Box>
                    <Box pt={1} pr={1}>
                        <Link underline='always' color='primary'
                            style={{ cursor: 'pointer' }}
                            onClick={updateAllNotifsAsRead}>Mark all as read</Link>
                    </Box>
                </Box>

                {notifications.map((notification, index) => {
                    if (notification.is_today && firstNotifToday === null) {
                        setFirstNotifToday(index);
                    }

                    return (
                        <Box key={`notif-${index}`}>
                            {DisplayTag(firstNotifToday, index, t, classes)}
                            <MenuItem onClick={() => viewNotif(notification, handleClose)}
                                className={classes.menuItem}
                                ref={index === notifications.length - 1 ? lastNotifRef : null}>
                                <Grid container={true}>
                                    <Grid item={true}>
                                        <Avatar src={notification.actor.profile_img} />
                                    </Grid>
                                    <Grid item={true}>
                                        <Box fontFamily='Inter, "Noto Sans Myanmar"' ml={1} mr={1}
                                            fontSize={12} className={!notification.unread ? classes.readText : ''}>
                                            {notification.actor && `${notification.actor.user.first_name} ${notification.actor.user.last_name} ${notification.verb}`}.
                                        </Box>
                                        <Box fontFamily='Inter, "Noto Sans Myanmar"' ml={1} mr={1}
                                            fontSize={12} className={classes.timeAgo}>
                                            <TimeAgo timestamp={notification.timestamp}
                                                timeStyle='round-minute' />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Badge variant='dot' className={`${notification.unread ? badgeClasses.unread : ''}`}
                                    badgeContent={''} />
                            </MenuItem>
                        </Box>
                    );
                })}

                {loading && <Box component='span' fontFamily='Inter, "Noto Sans Myanmar"' fontSize={12}
                    mx={2}>{t('text.loading')}...</Box>}
            </Menu>
        </>
    );
}

function DisplayTag(firstNotifToday, index, t, classes) {
    return (
        <>
            {firstNotifToday !== null && index === 0 && <Box className={classes.notifTag}>{t('text.today')}</Box>}
            {firstNotifToday !== null && index === firstNotifToday + 1 && <Box className={classes.notifTag}>{t('text.older')}</Box>}
            {firstNotifToday === null && index === 0 && <Box className={classes.notifTag}>{t('text.older')}</Box>}
        </>
    );
}
