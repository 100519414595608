const SET_WHOS_ONLINE = 'SET_WHOS_ONLINE';

export function updateWhosOnline(whosOnline: Array): any {
    return {
        type: SET_WHOS_ONLINE,
        whosOnline
    };
}

const initialState = {
    whosOnline: []
};

export default function onlinePresence(state: Object = initialState, action: Object):
  | any
  | {
    whosOnline: Array
  } {
    switch (action.type) {
        case SET_WHOS_ONLINE:
            return {
                ...state,
                whosOnline: action.whosOnline
            };
        default:
            return state;
    }
}
