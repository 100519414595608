import {
    withStyles,
    CircularProgress
} from '@material-ui/core';
import './spinner.scss';

const Spinner = withStyles((theme) => ({
    root: {
        '&.MuiCircularProgress-colorPrimary': {
            color: `${theme.palette.primary.main} !important`
        }
    }
}))(CircularProgress);

export default Spinner;
