import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Grid,
    Box,
    Divider,
    Link
} from '@material-ui/core';
import { design, footerLinks } from '../../../config/constants';
import { ReactComponent as Logo } from '../../../assets/svg/platform4dialogue-logo.svg';
import './footer.scss';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 100
    },
    outerBox: {
        backgroundColor: theme.palette.neutrals[100],
        paddingTop: 48,
        paddingLeft: design.indentPadding,
        paddingRight: design.indentPadding,

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    upperSection: {
        paddingBottom: 32
    },
    bottomSection: {
        paddingTop: 32,
        paddingBottom: 48
    },
    consultations: {
        marginBottom: 20
    },
    about: {
        marginBottom: 20
    },
    aboutDesc: {
        width: 350
    },
    link: {
        letterSpacing: 1.2,
        textTransform: 'uppercase',
        paddingLeft: 28,
        marginLeft: 35,
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px !important',
            paddingLeft: 0
        }
    },
    copyright: {
        float: 'left',
        [theme.breakpoints.down('xs')]: {
            float: 'right'
        }
    },
    policy: {
        float: 'right'
    },
    footerLink: {
        color: theme.palette.neutrals[800]
    }
}));

type Props = {
    className?: string
}

export default function MuiFooter({ className = '' }: Props): React$Element<any> {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <footer className={`footer ${className} ${classes.wrapper}`} >
            <Box className={classes.outerBox}>
                <Grid container={true} className={classes.upperSection} spacing={2}>
                    <Grid item={true} lg={3}>
                        <Logo style={{ height: 39, width: 160 }} />
                    </Grid>
                    <Grid item={true} md={6} lg={6}>
                        <Box fontWeight={500} letterSpacing={1.2}>
                            {t('title.about').toUpperCase()}
                        </Box>
                        <Box className={`${classes.aboutDesc}`} mt={2}>
                            Platform4Dialogue encourages, enables and supports inclusive online dialogue for practitioners, professionals and peacebuilders on a range of different issues.
                        </Box>
                    </Grid>
                    <Grid item={true} sm={3} md={3}
                        lg={3}>
                        <Box fontSize={12} fontWeight={500}
                            className={classes.link}
                            display='block' mb={2}
                            component='span'>
                            <Link href={footerLinks.consultations}
                                className={classes.footerLink}>{t('links.consultations')}</Link>
                        </Box>
                        <Box fontSize={12} fontWeight={500}
                            className={classes.link}
                            display='block' mb={2}
                            component='span'>
                            <Link href={footerLinks.about}
                                className={classes.footerLink}>{t('title.about')}</Link>
                        </Box>
                        <Box fontSize={12} fontWeight={500}
                            className={classes.link}
                            display='block' mb={2}
                            component='span'>
                            <Link href={footerLinks.contactUs}
                                target='_blank'
                                className={classes.footerLink}>{t('links.contact_us')}</Link>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
                <Box className={classes.bottomSection}>
                    <Box component='span' className={classes.copyright}>© {new Date().getFullYear()}. Platform4Dialogue</Box>
                    <Box className={classes.policy}>
                        <Box fontSize={12} fontWeight={500} letterSpacing={1.2}>
                            <span className={classes.link}>
                                <Link href={footerLinks.privacyPolicy}
                                    className={classes.footerLink}>{t('links.privacy_policy')}</Link>
                            </span>
                            <span className={classes.link}>
                                <Link href={footerLinks.termsOfService}
                                    className={classes.footerLink}>{t('links.terms_of_service')}</Link>
                            </span>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </footer>
    );
}
