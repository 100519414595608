// @flow
import React from 'react';
import { Box } from '@material-ui/core';
import './wrapper-background.scss';

type Props = {
    children: any,
    className?: string
}

export default function WrapperBackground({ children, className = '' }: Props): React$Element<"div"> {
    return (
        <Box className={`wrapper-background ${className}`} >
            {children}
        </Box>
    );
}
