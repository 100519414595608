import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .use(backend)
    .init({
        supportedLngs: ['en', 'ar', 'pt', 'es', 'fr'],
        fallbackLng: 'en',
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        },
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
