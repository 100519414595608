import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import { Tooltip } from '@material-ui/core';
import './time-ago.scss';

type Props = {
    className?: String,
    timestamp: String,
    timeStyle?: String
}

export default function TimeAgo({ className = '', timestamp = '2022-01-01T00:00:00Z', timeStyle = 'twitter-minute-now' }: Props): React$Element<any> {
    const TooltipContainer = ({ verboseDate, children, ...rest }) => (
        <Tooltip title={verboseDate}>{children}</Tooltip>
    );

    return (
        <ReactTimeAgo date={new Date(timestamp)} locale='en-US' timeStyle={timeStyle}
            wrapperComponent={TooltipContainer}
            tooltip={false} />
    );
}
