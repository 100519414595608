import React from 'react';
import {
    makeStyles,
    Backdrop
} from '@material-ui/core';
import Spinner from '../Spinner/Spinner';
import './loading.scss';

const useStyles = makeStyles(theme => ({
    backdrop: {
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main
    }
}));

type Props = {
    className?: String
}

export default function Loading({ className = '' }: Props): React$Element<any> {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <Spinner />
        </Backdrop>
    );
}
