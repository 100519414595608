import { makeStyles } from '@material-ui/core';

export const useBadgeStyles = makeStyles(theme => ({
    online: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.green[500]
        }
    },
    away: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.red[500]
        }
    },
    unread: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.primary.main
        }
    },
    outline: {
        '& .MuiBadge-badge': {
            border: `1px solid ${theme.palette.neutrals[400]}`
        }
    }
}));
