import React from 'react';
import { ReactComponent as ComfortableView } from '../assets/svg/comfortable-view.svg';
import { ReactComponent as CompactView } from '../assets/svg/compact-view.svg';
import { ReactComponent as LinearView } from '../assets/svg/linear-view.svg';
import { config } from '../config/constants';

/**
 * Capitalize a string
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str: string): string {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export const isValidEmail = (str: string): boolean => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);

export const isRTL = (direction: string): boolean => direction === 'rtl';

export const isLTR = (direction: string): boolean => direction === 'ltr';

export const orientation = () => document.getElementsByTagName('body')[0].getAttribute('dir');

export const hasTwoOrMoreComments = thread => !(thread.replies.length <= 1 && thread.replies[0] && thread.replies[0].replies.length === 0) && thread.replies.length !== 0;

export const redirectToNotif = (consultationId, topicId, commentId, history) => {
    history.push(`/consultation/${consultationId}/topic/${topicId}?notif=${commentId}`);
    window.location.reload();
};

export const consultationPeriod = (data) => {
    const startDate = new Date(data.start);
    const startMonth = startDate.toLocaleString('default', { month: 'short' });
    const startDay = startDate.getDate();
    const startYear = startDate.getFullYear();

    const endDate = new Date(data.end);
    const endMonth = endDate.toLocaleString('default', { month: 'short' });
    const endDay = endDate.getDate();
    const endYear = endDate.getFullYear();

    return `${startDay.toString().length === 1 ? '0' : ''}${startDay} ${startMonth} ${startYear === endYear ? '' : startYear} - ${endDay.toString().length === 1 ? '0' : ''}${endDay} ${endMonth} ${endYear}`;
};

export const author = (thread, type) => {
    if (thread === null || !thread.author) return '';

    if (thread.is_anonymous) return 'Anonymous';

    const firstName = thread.author.user_profile.user.first_name;
    const lastName = thread.author.user_profile.user.last_name;

    switch (type) {
        case 'first':
            return firstName;
        case 'last':
            return lastName;
        default:
            return `${firstName} ${lastName}`;
    }
};

export const avatar = (thread) => {
    if (thread == null || !thread.author) return '';

    return thread.is_anonymous ? '' : `${thread.author.user_profile.profile_img}`;
};

export const getViewTypes = (open) => {
    const iconStyle = {
        width: open ? 12 : 19,
        height: open ? 12 : 19,
        color: open ? '#595959' : '#262626'
    };

    const viewTypes = [
        {
            label: 'Threads',
            value: 'comfortable',
            icon: <ComfortableView color='red' style={iconStyle} />
        },
        // {
        //     label: 'Compact',
        //     value: 'compact',
        //     icon: <CompactView style={iconStyle} />
        // },
        {
            label: 'Chronological',
            value: 'linear',
            icon: <LinearView style={iconStyle} />
        }
    ];

    return viewTypes;
};

export const disableTopicBtn = (post) => {
    if (!post.is_admin && !post.is_live) return true;

    return false;
};

export const isSessionDateAccessible = (session) => {
    const publishDate = new Date(session.publish);
    const now = new Date();

    if (session.is_admin || session.is_facilitator) return true;
    if (publishDate <= now) return true;

    return false;
};
