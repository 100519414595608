import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import * as rootReducer from './reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['projectState', 'clients']
};

export const history = createBrowserHistory();

const reducers = combineReducers({
    router: connectRouter(history),
    ...rootReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export let storeRef = null;

export default function configureAppStore(preloadedState) {
    const store = configureStore({
        reducer: persistedReducer, // { router: connectRouter(history), ...rootReducer },
        middleware: [thunk, routerMiddleware(history), ...getDefaultMiddleware({ serializableCheck: false })],
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    });

    storeRef = store;

    return store;
}
